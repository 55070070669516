import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import "./App.scss";

import PrivateRoute from "../Routing/PrivateRoute";
import LoginView from "../../views/Login/LoginView";
import ProofOfIdentityView from "../../views/Documents/ProofOfIdentityView";
import ProofOfAddressView from "../../views/Documents/ProofOfAddressView";
import ProofOfIncomeView from "../../views/Documents/ProofOfIncomeView";
import OtherDocumentsViews from "../../views/Documents/OtherDocumentsView";
import CompleteView from "../../views/Documents/CompleteView";
import ApplicationInvalidView from "../../views/Application/ApplicationInvalidView";
import FourOhFourView from "../../views/FourOhFourView";
import UnauthorizedView from "../../views/UnauthorizedView";
import Navbar from "./Navbar";
import AnonymousNavbar from "./AnonymousNavbar";
import ContactUsView from "../../views/ContactUsView";
import BranchInformation from "./BranchInformation";
import ErrorView from "../../views/Error";

import {library} from "@fortawesome/fontawesome-svg-core";
import {
  faInfoCircle,
  faCog,
  faCheck,
  faCheckSquare,
  faAddressCard,
  faPassport,
  faIdCardAlt,
  faFileAlt,
  faFileInvoice,
  faMoneyCheck,
  faFilePdf
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faInfoCircle,
  faCog,
  faCheck,
  faCheckSquare,
  faAddressCard,
  faPassport,
  faIdCardAlt,
  faFileAlt,
  faFileInvoice,
  faMoneyCheck,
  faFilePdf
);

class App extends React.Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Switch>
            <Route path="/login/:linkId" component={LoginView}/>

            <PrivateRoute path="/id">
              <Navbar/>
              <ProofOfIdentityView/>
              <BranchInformation/>
            </PrivateRoute>

            <PrivateRoute path="/address">
              <Navbar/>
              <ProofOfAddressView/>
              <BranchInformation/>
            </PrivateRoute>

            <PrivateRoute path="/income">
              <Navbar/>
              <ProofOfIncomeView/>
              <BranchInformation/>
            </PrivateRoute>

            <PrivateRoute path="/other">
              <Navbar/>
              <OtherDocumentsViews/>
              <BranchInformation/>
            </PrivateRoute>

            <PrivateRoute path="/complete">
              <Navbar/>
              <CompleteView/>
              <BranchInformation/>
            </PrivateRoute>

            <Route path="/contact" render={props =>
              <div>
                <AnonymousNavbar/>
                <ContactUsView/>
              </div>
            }/>

            <Route path="/invalid" render={props =>
              <div>
                <AnonymousNavbar/>
                <ApplicationInvalidView/>
              </div>
            }/>

            <Route path="/unauthorized" render={props =>
              <div>
                <AnonymousNavbar/>
                <UnauthorizedView/>
              </div>
            }/>

            <Route path="/error" render={props =>
              <div>
                <AnonymousNavbar/>
                <ErrorView/>
              </div>
            }/>

            <Route path="/:linkId" component={LoginView}/>

            <Route render={props =>
              <div>
                <AnonymousNavbar/>
                <FourOhFourView/>
              </div>
            }/>

          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
