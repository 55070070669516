import SessionService from "../Auth/SessionService";
import {appInsights} from "../Monitoring/AppInsights";

class DocumentService {

  fileToBlob(file) {
    return new Blob([file], {type: file.type});
  }

  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], {type: mimeString});
  }

  buildFormData(images, documentDescription) {
    const formData = new FormData();

    if (images) {
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        if (image.name) {
          formData.append(
            `${documentDescription}`,
            this.fileToBlob(image),
            image.name
          );
        } else {
          formData.append(
            `${documentDescription}`,
            this.dataURItoBlob(image),
            `${i}.png`
          );
        }
      }
    }

    return formData;
  }

  buildFileSummary(files) {
    try {
      let fileMetrics = [];
      files.forEach((file => {
        fileMetrics.push({name: file.name, size: file.size});
      }));
      return JSON.stringify(fileMetrics);
    } catch (e) {
      // Dont let the logging fail due to a failure in this function
      return '';
    }
  }

  async uploadDocument(images, documentType, documentDescription) {
    let sessionService = new SessionService();
    const applicationId = sessionService.getApplicationId();
    const customerId = sessionService.getCustomerId();
    const body = this.buildFormData(images, documentDescription);

    return await fetch(
      `/api/customer/${customerId}/application/${applicationId}/document/${documentType}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionService.getToken()}`,
        },
        body,
      }
    ).then((res) => {
      if (res.ok) {
        sessionService.recordDocumentAdded({documentType});
        return res.json();
      } else {
        return res.text().then(text => {
          appInsights.trackTrace({
            message: `uploadDocument for Customer: ${customerId} Application: ${applicationId} failed. Response code: ${res.status}. Text: ${text}`
          });
          throw res.status;
        });
      }
    })
      .catch((e) => {
        const fileSummary = this.buildFileSummary(images);
        appInsights.trackException({
          exception: new Error(e),
          properties: {'method': 'uploadDocument', customerId, applicationId, fileSummary}
        });
        throw e;
      });
  }
}

export default DocumentService;
