import React from "react";
import {withRouter, Redirect} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DocumentPreview from "./DocumentPreview.js";
import DocumentCapture from "./DocumentCapture";
import DocumentService from "../../services/Document/DocumentService";
import Alerter from "../App/Alerter";
import BackToAmendDocumentType from "./BackToAmendDocumentType";
import MoveNext from "./MoveNext";
import RedirectToLogin from "../App/RedirectToLogin";
import Instructions from "./Instructions";

class GeneralDataCapture extends React.Component {
  CaptureState = {
    None: 0,
    Data: 1,
    Save: 2,
  };

  state = {
    documents: [],
    isSaving: false,
    isSaved: false,
    shouldRedirect: false,
    isAuthenticated: true,
    errorCount: 0,
  };

  handleDocumentReceived(document) {
    document.type = this.getCaptureState();
    this.storeDocumentInState(document);
  }

  handleDocumentRemoved(documentId) {
    let documents = this.state.documents;
    documents.forEach(function (item, index, object) {
      if (item?.id === documentId) {
        object.splice(index, 1);
      }
    });

    this.setState({
      documents,
    });
  }

  storeDocumentInState(document) {
    if (document.type !== this.CaptureState.None) {
      let documents = this.state.documents;
      documents.push(document);

      this.setState({
        documents,
      });
    }
  }

  getCaptureState() {
    return this.state.documents.length === 0
      ? this.CaptureState.Data
      : this.CaptureState.Save;
  }

  getDocumentPreviews() {
    let previews = [];

    if (this.state.documents.length !== 0) {
      this.state.documents.forEach((doc, i) => {
        let preview = (
          <div className="col-md-4" key={i}>
            <DocumentPreview
              title="Preview"
              document={doc}
              onDocumentRemoved={this.handleDocumentRemoved}
            />
          </div>
        );
        previews.push(preview);
      });
      return <div className="row previews">{previews}</div>;
    }
  }

  hideAlert() {
    this.setState({
      isError: false,
    });
  }

  async handleFileSaved() {
    const self = this;

    self.setState({
      isSaving: true,
    });

    let uploadSuccess = true;
    try {
      let documentService = new DocumentService();
      let result = await documentService.uploadDocument(
        this.state.documents.map((doc) => doc.file),
        this.props.proofType,
        this.props.documentDescription
      );

      result.forEach((res) => {
        if (!res.success) {
          uploadSuccess = false;
        }
      });
    } catch (e) {
      if (e === 401) {
        self.setState({
          isAuthenticated: false
        })
      }

      uploadSuccess = false;
    }

    if (!uploadSuccess) {
      const errorCount = self.state.errorCount;
      self.setState({
        isSaving: false,
        isSaved: false,
        isError: true,
        errorCount: errorCount + 1,
      });
    } else {
      self.setState({
        isSaving: false,
        isSaved: true
      });
    }
  }

  moveNext() {
    this.setState({
      shouldRedirect: true,
    });
  }

  render() {
    this.handleFileSaved = this.handleFileSaved.bind(this);
    this.handleDocumentRemoved = this.handleDocumentRemoved.bind(this);
    this.handleDocumentReceived = this.handleDocumentReceived.bind(this);
    const captureState = this.getCaptureState();

    if (!this.state.isAuthenticated) {
      return (<RedirectToLogin step={this.props.currentStage}/>)
    }

    if (this.state.errorCount > 3) {
      return <Redirect to="/contact"/>;
    }

    if (this.state.isSaving && !this.state.isSaved) {
      return (
        <div className="saving">
          <div className="centered">
            <FontAwesomeIcon icon={["fas", "cog"]} spin size="4x"/>
            <p className="h5">Saving, please wait...</p>
          </div>
        </div>
      );
    }

    if (
      this.state.isSaved &&
      !this.state.isSaving &&
      !this.state.shouldRedirect
    ) {
      return (
        <div className="saved">
          <div className="centered">
            <FontAwesomeIcon icon={["fas", "check"]} size="4x"/>
            <p className="h5">
              Your {this.props.documentDescription} has been saved!
            </p>
            <MoveNext documentType={this.props.proofDescription} moreCommand={() => this.props.backCommand()}
                      nextCommand={() => this.moveNext()}/>
          </div>
        </div>
      );
    }

    if (this.state.isSaved && this.state.shouldRedirect) {
      return <Redirect to={`/${this.props.nextStage}`}/>;
    }

    return (
      <div className="document-selection">
        {captureState === this.CaptureState.Save ? (
          <div>
            <div className="heading h5">
              Add more pages below and click save when you are finished.
            </div>
            <Alerter
              show={this.state.isError}
              message="There was an issue uploading your documents. Please try again."
              hideAlert={() => this.hideAlert()}
            />
            <div>
              <button
                type="button"
                className="btn btn-lg btn-success"
                onClick={this.handleFileSaved}
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div className="heading h5">
              Please provide your {this.props.documentDescription.toLowerCase()}.
            </div>
            <Instructions/>
          </div>
        )}
        <BackToAmendDocumentType backCommand={() => this.props.backCommand()}/>
        <DocumentCapture onDocumentReceived={this.handleDocumentReceived}/>
        {this.getDocumentPreviews()}
      </div>
    );
  }
}

export default withRouter(GeneralDataCapture);
