import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BranchHelp from "../components/App/BranchHelp";
import withAppInsights from '../services/Monitoring/AppInsights';

function ErrorView() {

  return (
    <div className="container">
      <div className="complete">
        <div className="centered">
          <FontAwesomeIcon icon={['fas', 'info-circle']} size="6x"/>
          <p className="h5">Sorry, we are unable to upload your documents at the moment.</p>
          <BranchHelp/>
        </div>
      </div>
    </div>
  );
}

export default withAppInsights(ErrorView);