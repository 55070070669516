import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReferenceDataService from "../../services/ReferenceData/ReferenceDataService";
import "./AcceptableDocuments.scss";
import {withRouter} from "react-router-dom";

class AcceptableDocuments extends React.Component {
  state = {
    documentTypes: [],
  };

  async componentDidMount() {
    let referenceDataService = new ReferenceDataService();
    try {
      let documentTypes = await referenceDataService.getAcceptableDocuments(this.props.documentType);
      this.setState({
        documentTypes
      });
    } catch (e) {
      window.sessionStorage.removeItem("token");
    }
  }

  render() {
    return (
      <div className="document-selection">
        <h5>Please select the type of document you are capturing.</h5>
        <div className="row acceptable-documents">

          {this.state.documentTypes.map((documentType, i) => (
            <div className="col-sm" key={i}>
              <div className="card text-center h-100 acceptable-document">
                <div className="card-body h-100 acceptable-document" onClick={() => {
                  this.props.setDocumentType(documentType)
                }}>
                  <FontAwesomeIcon icon={["fas", "file-alt"]} size="4x"/>
                  <span className="title">{documentType}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(AcceptableDocuments);
