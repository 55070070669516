import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BranchInformation from "../../components/App/BranchInformation";
import withAppInsights from '../../services/Monitoring/AppInsights';

function ApplicationInvalidView() {


  return (
    <div className="container">
      <div className="complete">
        <div className="centered">
          <FontAwesomeIcon icon={['fas', 'info-circle']} size="6x"/>
          <p className="h5">Sorry, it appears the link to upload your application documents is no longer valid.</p>
          <BranchInformation/>
        </div>
      </div>
    </div>
  );
}

export default withAppInsights(ApplicationInvalidView);