import SessionService from "../Auth/SessionService";

class AuthenticationService {

  async authenticate(linkId, surname, dateOfBirth, postCode) {
    return await fetch(`/api/authenticate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        linkId,
        dob: dateOfBirth,
        postCode,
        name: surname,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          const {status} = res;
          throw status;
        } else {
          return res.json();
        }
      })
      .then((json) => {
        return json;
      });
  }

  async refresh() {

    let sessionService = new SessionService();
    const token = await fetch(`/api/refreshToken`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionService.getToken()}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          const {status} = res;
          throw status;
        } else {
          return res.json();
        }
      });
    sessionService.setToken(token);
  }

  isAuthenticated() {
    let sessionService = new SessionService();
    return sessionService.getIsAuthenticated();
  }
}

export default AuthenticationService;
