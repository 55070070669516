// AppInsights.js
import {ApplicationInsights} from '@microsoft/applicationinsights-web'
import {ReactPlugin, withAITracking} from '@microsoft/applicationinsights-react-js'
import {createBrowserHistory} from 'history';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {history: browserHistory}
    }
  }
})
ai.loadAppInsights();
ai.trackPageView();

export default (Component) => withAITracking(reactPlugin, Component)
export const appInsights = ai.appInsights