import React from "react";
import {withRouter} from "react-router-dom";

import SessionService from "../../services/Auth/SessionService";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout() {
    const sessionService = new SessionService();
    const linkId = sessionService.getLinkId();

    sessionService.clear();

    this.props.history.push(`/login/${linkId}`);
  }

  render() {
    return (
      <nav className="navbar justify-content-between">
        <a className="navbar-brand" href="/">
          <img
            src="images/everyday-loans-logo-sm.png"
            className="logo"
            alt=""
          />
        </a>
        <button
          className="btn btn-outline-success my-2 my-sm-0"
          type="submit"
          onClick={this.handleLogout}
        >
          Logout
        </button>
      </nav>
    );
  }
}

export default withRouter(Navbar);
