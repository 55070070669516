import React from "react";

class MoveNext extends React.Component {

  render() {
    return (
      <div className="moveNext">
        <div className="skip heading h6">
          Do you want to add more {this.props.documentType} documents
          or go to the next step?
        </div>
        <div className="actions">
          <button
            type="button"
            className="btn btn-md btn-primary"
            onClick={() => this.props.moreCommand()}>
            More
          </button>
          <button
            type="button"
            className="btn btn-md btn-success"
            onClick={() => this.props.nextCommand()}>
            Next
          </button>
        </div>
      </div>
    );
  }
}

export default MoveNext;