import SessionService from "../../services/Auth/SessionService";
import {Redirect} from "react-router-dom";
import React from "react";

class RedirectToLogin extends React.Component {

  render() {
    const sessionService = new SessionService()
    const linkId = sessionService.getLinkId();
    return <Redirect to={{
      pathname: `/login/${linkId}`,
      state: {
        step: `${this.props.step}`
      }
    }}/>;
  }
}

export default RedirectToLogin; 