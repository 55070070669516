import React from "react";

class BackToAmendDocumentType extends React.Component {

  render() {
    return (
      <div className="skip heading h6">
        or you can go
        <button
          type="button"
          className="btn btn-sm btn-success"
          onClick={() => this.props.backCommand()}>
          Back
        </button>
        and select a different document type.
      </div>
    );
  }
}

export default BackToAmendDocumentType;