import React from "react";
import {withRouter} from "react-router-dom";
import Steps from "../../components/App/Steps.js";
import "./index.scss";
import AcceptableDocuments from "../../components/Documents/AcceptableDocuments";
import GeneralDataCapture from "../../components/Documents/GeneralDataCapture";
import AuthenticationService from "../../services/Auth/AuthenticationService";
import RedirectToLogin from "../../components/App/RedirectToLogin";
import withAppInsights from '../../services/Monitoring/AppInsights';

class ProofOfAddressView extends React.Component {
  state = {
    documentDescription: null,
    isAuthenticated: true
  };

  async componentDidMount() {
    try {
      const authenticationService = new AuthenticationService()
      await authenticationService.refresh();
    } catch (e) {
      this.setState(
        {isAuthenticated: false}
      )
    }
  }

  setDocumentType(documentType) {
    this.setState({
      documentDescription: documentType
    })
  }

  handleDocumentTypeReset() {
    this.setState({
      documentDescription: null,
    });
  }

  render() {
    const isDocumentTypeSelected = this.state.documentDescription != null;

    if (!this.state.isAuthenticated) {
      return (<RedirectToLogin step="address"/>)
    }

    if (isDocumentTypeSelected) {
      return (
        <div>
          <Steps currentStep={2}/>
          <GeneralDataCapture
            backCommand={() => this.handleDocumentTypeReset()}
            proofType="proofofaddress"
            proofDescription="proof of address"
            documentDescription={this.state.documentDescription}
            currentStage="address"
            nextStage="income"
          />
        </div>
      );
    } else {
      return (
        <div>
          <Steps currentStep={2}/>
          <div>
            <div>
              <AcceptableDocuments documentType={"proofofaddress"}
                                   setDocumentType={(type) => this.setDocumentType(type)}/>
            </div>
            <div className="skip heading h6">
              or
              <button
                type="button"
                className="btn btn-sm btn-success"
                onClick={() => this.props.history.push(`/income`)}
              >
                Skip
              </button>
              to the next stage
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withAppInsights(withRouter(ProofOfAddressView));
