class SessionService {

  getIsAuthenticated() {
    const isAuthenticated = window.sessionStorage.getItem("token");
    return isAuthenticated !== null;
  }

  getLinkId() {
    return window.sessionStorage.getItem("linkId");
  }

  setLinkId(linkId) {
    window.sessionStorage.setItem("linkId", linkId);
  }

  getApplicationId() {
    return window.sessionStorage.getItem("applicationId");
  }

  setApplicationId(applicationId) {
    window.sessionStorage.setItem("applicationId", applicationId);
  }

  getCustomerId() {
    return window.sessionStorage.getItem("customerId");
  }

  setCustomerId(customerId) {
    window.sessionStorage.setItem("customerId", customerId);
  }

  getCustomerName() {
    return window.sessionStorage.getItem("customerName");
  }

  setCustomerName(customerName) {
    window.sessionStorage.setItem("customerName", customerName);
  }

  getBranch() {
    const branch = window.sessionStorage.getItem("branch");
    if (branch) {
      return JSON.parse(branch);
    } else {
      return null
    }
  }

  setBranch(branch) {
    window.sessionStorage.setItem("branch", JSON.stringify(branch));
  }

  getToken() {
    return window.sessionStorage.getItem("token");
  }

  setToken(token) {
    window.sessionStorage.setItem("token", token);
  }

  getDocumentsSaved() {
    let addedDocuments = [];
    const addedDocumentsString = window.sessionStorage.getItem("documentRecord");

    if (addedDocumentsString) {
      addedDocuments = JSON.parse(addedDocumentsString);
    }
    return addedDocuments;
  }

  recordDocumentAdded(documentRecord) {
    const addedDocuments = this.getDocumentsSaved();
    addedDocuments.push(documentRecord);
    window.sessionStorage.setItem("documentRecord", JSON.stringify(addedDocuments));
  }

  getNumberOfDocumentsAdded() {
    const addedDocuments = this.getDocumentsSaved();
    return addedDocuments.length;
  }

  clear() {
    window.sessionStorage.clear();
  }
}

export default SessionService;
