import React from "react";
import {withRouter, Redirect} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Steps from "../../components/App/Steps.js";
import DocumentPreview from "../../components/Documents/DocumentPreview.js";
import DocumentCapture from "../../components/Documents/DocumentCapture";
import DocumentService from "../../services/Document/DocumentService";
import Alerter from "../../components/App/Alerter";
import BackToAmendDocumentType from "../../components/Documents/BackToAmendDocumentType";
import AuthenticationService from "../../services/Auth/AuthenticationService";
import Instructions from "../../components/Documents/Instructions";
import withAppInsights from '../../services/Monitoring/AppInsights';

import "./index.scss";

class OtherDocumentsView extends React.Component {

  CaptureState = {
    CheckHasMoreDocuments: 0,
    SelectDocumentType: 1,
    CaptureDocument: 2,
    PreviewDocument: 3,
    SavingDocuments: 4,
    DocumentsSaved: 5,
    RedirectToComplete: 6,
    RedirectToContact: 7,
    CaptureAnotherDocument: 8
  };

  state = {
    document: null,
    documentType: null,
    hasMoreDocuments: null,
    isDocumentTypeSelected: false,
    isSaving: false,
    isSaved: false,
    shouldRedirect: false,
    errorCount: 0,
    maxDocuments: 10
  };

  async componentDidMount() {
    const authenticationService = new AuthenticationService()
    await authenticationService.refresh();
  }

  handleDocumentReceived(document) {
    document.type = this.state.documentType;

    this.setState({
      document
    });
  }

  handleDocumentRemoved() {
    this.removeDocumentFromState();
  }

  handleDocumentTypeReset() {
    this.setState({
      isDocumentTypeSelected: false,
      documentType: null,
      document: null,
      isSaved: false
    });
  }

  handleReset() {
    this.setState({
      hasMoreDocuments: null,
      isDocumentTypeSelected: false,
      documentType: null,
      document: null,
      isSaved: false
    });
  }

  handleComplete() {
    this.setState({
      shouldRedirect: true
    });
  }

  removeDocumentFromState() {
    this.setState({
      document: null
    });
  }

  getDocumentPreview() {
    let document = this.state.document;
    return (
      <div className="row previews">
        <div className="col-md-12">
          <DocumentPreview
            title={"Preview"}
            document={document}
            onDocumentRemoved={this.handleDocumentRemoved}
          />
        </div>
      </div>
    );
  }

  hideAlert() {
    this.setState({
      isError: false,
    });
  }

  async handleFileSaved() {
    const self = this;

    self.setState({
      isSaving: true,
    });

    let uploadSuccess = true;
    try {
      let documentService = new DocumentService();
      let result = await documentService.uploadDocument(
        [this.state.document.file],
        'other',
        this.state.document.type
      );

      result.forEach((res) => {
        if (!res.success) {
          uploadSuccess = false;
        }
      });
    } catch (e) {
      uploadSuccess = false;
    }

    if (!uploadSuccess) {
      const errorCount = self.state.errorCount;
      self.setState({
        isSaving: false,
        isSaved: false,
        isError: true,
        errorCount: errorCount + 1,
      });
    } else {
      self.setState({
        isSaving: false,
        isSaved: true,
      });

      setTimeout(function () {
        self.handleReset();
      }, 1000);
    }
  }

  handleDocumentTypeSelected(type) {
    this.setState({
      isDocumentTypeSelected: true,
      documentType: type
    });
  }

  handleHasMoreDocuments() {
    this.setState({
      hasMoreDocuments: true
    });
  }

  getCaptureState() {
    if (!this.state.isSaved && this.state.shouldRedirect) {
      return this.CaptureState.RedirectToComplete;
    }

    if (!this.state.isSaving && this.state.isSaved && this.state.shouldRedirect) {
      return this.CaptureState.CaptureAnotherDocument;
    }

    if (this.state.hasMoreDocuments == null) {
      return this.CaptureState.CheckHasMoreDocuments;
    }

    if (!this.state.hasMoreDocuments) {
      return this.CaptureState.RedirectToComplete
    }

    if (this.state.hasMoreDocuments && !this.state.document && !this.state.documentType) {
      return this.CaptureState.SelectDocumentType;
    }

    if (this.state.errorCount > 3) {
      return this.CaptureState.RedirectToContact;
    }

    if (this.state.isSaving && !this.state.isSaved) {
      return this.CaptureState.SavingDocuments;
    }

    if (!this.state.isSaving && this.state.isSaved && !this.state.shouldRedirect) {
      return this.CaptureState.DocumentsSaved;
    }

    if (this.state.document) {
      return this.CaptureState.PreviewDocument;
    }

    return this.CaptureState.CaptureDocument;
  }

  prettifyDocumentType(documentType) {
    const prettifiedDocumentType = documentType.toLowerCase();
    if (prettifiedDocumentType === "other") {
      return "document";
    }
    return prettifiedDocumentType;
  }

  render() {
    this.handleHasMoreDocuments = this.handleHasMoreDocuments.bind(this);
    this.handleDocumentTypeReset = this.handleDocumentTypeReset.bind(this);
    this.handleFileSaved = this.handleFileSaved.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.handleDocumentRemoved = this.handleDocumentRemoved.bind(this);
    this.handleDocumentReceived = this.handleDocumentReceived.bind(this);
    this.getCaptureState = this.getCaptureState.bind(this);

    let captureState = this.getCaptureState();

    if (captureState === this.CaptureState.RedirectToContact) {
      return <Redirect to="/contact"/>;
    }

    if (captureState === this.CaptureState.SavingDocuments) {
      return (
        <div className="saving">
          <div className="centered">
            <FontAwesomeIcon icon={["fas", "cog"]} spin size="4x"/>
            <p className="h5">Saving, please wait...</p>
          </div>
        </div>
      );
    }

    if (captureState === this.CaptureState.DocumentsSaved) {
      return (
        <div className="saved">
          <div className="centered">
            <FontAwesomeIcon icon={["fas", "check"]} size="4x"/>
            <p className="h5">Your {this.prettifyDocumentType(this.state.documentType)} has been saved!</p>
          </div>
        </div>
      );
    }

    if (captureState === this.CaptureState.RedirectToComplete) {
      return <Redirect to="/complete"/>;
    }

    if (captureState === this.CaptureState.CheckHasMoreDocuments) {
      return (
        <div>
          <Steps currentStep={4}/>
          <div className="document-selection margin top bottom">
            <div className="heading h5">
              Do you have any additional documents you want to capture?
            </div>
            <div>
              <button type="button" className="btn btn-lg btn-primary margin right"
                      onClick={this.handleHasMoreDocuments}>Yes
              </button>
              <button type="button" className="btn btn-lg btn-success" onClick={this.handleComplete}>No</button>
            </div>
          </div>
        </div>
      );
    }

    if (captureState === this.CaptureState.SelectDocumentType) {
      return (
        <div>
          <Steps currentStep={4}/>
          <div className="document-selection margin top bottom">
            <h5>Please select the type of document you are capturing.</h5>
            <div className="row document-types margin top">
              <div className="col-sm">
                <div className="card text-center document-type"
                     onClick={() => this.handleDocumentTypeSelected("Bank statement")}>
                  <div className="card-body">
                    <FontAwesomeIcon icon={["fas", "money-check"]} size="2x"/>
                    <span className="title">Bank Statement</span>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="card text-center document-type"
                     onClick={() => this.handleDocumentTypeSelected("Pay slip")}>
                  <div className="card-body">
                    <FontAwesomeIcon icon={["fas", "file-invoice"]} size="2x"/>
                    <span className="title">Pay Slip</span>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="card text-center document-type"
                     onClick={() => this.handleDocumentTypeSelected("Other")}>
                  <div className="card-body">
                    <FontAwesomeIcon icon={["fas", "file-alt"]} size="2x"/>
                    <span className="title">Other</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row margin top">
              <div className="col-md-12  text-center ">
                <div className="skip heading h6">
                  Or you can
                  <button type="button" className="btn btn-sm btn-success margin right"
                          onClick={this.handleComplete}>Skip</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (captureState === this.CaptureState.CaptureDocument) {
      return (
        <div>
          <Steps currentStep={4}/>
          <div className="document-selection margin top bottom">
            <div className="heading h5">
              Please provide your {this.prettifyDocumentType(this.state.documentType)}.
            </div>
            <Instructions/>
            <BackToAmendDocumentType backCommand={() => this.handleDocumentTypeReset()}/>
            <DocumentCapture onDocumentReceived={this.handleDocumentReceived}/>
          </div>
        </div>
      );
    }

    if (captureState === this.CaptureState.PreviewDocument) {
      return (
        <div>
          <Steps currentStep={4}/>
          <div className="margin top bottom">
            <div className="heading h5">
              If you are happy with the preview of your {this.prettifyDocumentType(this.state.documentType)}, please
              press save.
            </div>
            <Alerter
              show={this.state.isError}
              message={"There was an issue uploading your " + this.prettifyDocumentType(this.state.documentType) + ". Please try again."}
              hideAlert={() => this.hideAlert()}/>
            <div>
              <button type="button" className="btn btn-lg btn-success margin right"
                      onClick={this.handleFileSaved}>Save
              </button>
              <BackToAmendDocumentType backCommand={() => this.handleDocumentTypeReset()}/>
              {this.getDocumentPreview()}
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default withAppInsights(withRouter(OtherDocumentsView));
