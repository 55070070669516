import React from "react";

class Instructions extends React.Component {

  render() {
    return (
      <div className="heading h6 instructions">
        <ul>
          <li>Documents should not be defaced or damaged in any way</li>
          <li>All 4 corners of the document should be visible in one image</li>
          <li>Letters must be dated within 90 days</li>
          <li>Identification documents must be valid</li>
        </ul>
      </div>
    );
  }
}

export default Instructions;