import React from 'react';
import {Route, Redirect} from 'react-router-dom';

import AuthenticationService from "../../services/Auth/AuthenticationService";
import SessionService from "../../services/Auth/SessionService";

function PrivateRoute(route) {

  const authenticationService = new AuthenticationService();
  const sessionService = new SessionService();
  const linkId = sessionService.getLinkId();

  if (authenticationService.isAuthenticated()) {
    return (
      <Route path={route.path}>
        {route.children}
      </Route>
    )
  }

  if (linkId) {
    return <Redirect to={`/login/${linkId}`}/>;
  }

  return <Redirect to="/unauthorized"/>;
}

export default PrivateRoute;