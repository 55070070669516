import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "react-html5-camera-photo/build/css/index.css";
import "./DocumentTypeSelector.scss";

class DocumentTypeSelector extends React.Component {
  state = {
    isDocumentTypeSelected: false,
  };

  handleDocumentTypeSelected(type) {
    this.setState({
      isDocumentTypeSelected: true,
    });

    this.props.onDocumentTypeSelected(type);
  }

  render() {
    const isDocumentTypeSelected = this.state.isDocumentyTypeSelected;

    return isDocumentTypeSelected ? (
      ""
    ) : (
      <div className="document-selection">
        <div className="heading h5">
          Please choose the proof of identity you have available.
        </div>

        <div className="row document-types">
          <div className="col-sm">
            <div
              className="card text-center document-type"
              onClick={() => this.handleDocumentTypeSelected("license")}
            >
              <div className="card-body">
                <FontAwesomeIcon icon={["fas", "address-card"]} size="4x"/>
                <span className="title">Drivers License</span>
              </div>
            </div>
          </div>

          <div className="col-sm">
            <div
              className="card text-center document-type"
              onClick={() => this.handleDocumentTypeSelected("passport")}
            >
              <div className="card-body">
                <FontAwesomeIcon icon={["fas", "passport"]} size="4x"/>
                <span className="title">Passport</span>
              </div>
            </div>
          </div>

          <div className="col-sm">
            <div
              className="card text-center document-type"
              onClick={() => this.handleDocumentTypeSelected("other")}
            >
              <div className="card-body">
                <FontAwesomeIcon icon={["fas", "id-card-alt"]} size="4x"/>
                <span className="title">Other</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DocumentTypeSelector;
