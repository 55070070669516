import React from "react";
import "./DocumentPreview.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isIE} from "../../utils";

class DocumentPreview extends React.Component {
  handleDocumentRemoved(e) {
    const documentId = e.target.getAttribute("data-document-id");
    this.props.onDocumentRemoved(documentId);
  }

  render() {
    this.handleDocumentRemoved = this.handleDocumentRemoved.bind(this);

    if (this.props.document) {
      const preview = isIE()
        ? <div className="card-body">
          <FontAwesomeIcon icon={["fas", "file-pdf"]} size="4x"/>
        </div>
        : <object data={this.props.document.preview} type="application/pdf">{this.props.document.name}</object>;

      return (
        <div className="preview">
          <h6>{this.props.title}</h6>
          <div className="actions">
            <button
              type="button"
              className="btn btn-danger"
              data-document-id={this.props.document.id}
              onClick={this.handleDocumentRemoved}
            >
              Remove
            </button>
          </div>
          {
            this.props.document.mimetype === "pdf"
              ? (preview)
              : (<img src={this.props.document.preview} alt="Preview"/>)
          }
          <h6>{this.props.document.name}</h6>
        </div>
      );
    } else {
      return (
        <div className="preview">
          <h6>{this.props.title}</h6>
          <div className="unavailable">
            <p className="caption">No preview available.</p>
          </div>
        </div>
      );
    }
  }
}

export default DocumentPreview;
