import SessionService from "../Auth/SessionService";

class ReferenceDataService {
  async getAcceptableDocuments(documentType) {
    let sessionService = new SessionService();

    return await fetch(`/api/referencedata/acceptabledocuments/${documentType}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionService.getToken()}`,
        },
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data;
      })
      .catch((e) => {
        return [];
      });
  }
}

export default ReferenceDataService;
