import React from 'react';
import {Redirect} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './index.scss';
import SessionService from "../../services/Auth/SessionService";
import {groupBy} from "../../utils";
import withAppInsights from '../../services/Monitoring/AppInsights';

class CompleteView extends React.Component {

  state = {
    shouldRedirect: false,
    redirectLocation: ""
  }

  sessionService = new SessionService();

  handleLogout() {
    const linkId = this.sessionService.getLinkId();
    this.sessionService.clear();

    this.setState({
      shouldRedirect: true,
      redirectLocation: `login/${linkId}`
    });
  }

  handleStartOver() {
    this.setState({
      shouldRedirect: true,
      redirectLocation: "id"
    });
  }

  documentsReceived(documentsOfTypeReceived, description) {
    if (documentsOfTypeReceived) {
      if (documentsOfTypeReceived.length === 1) {
        return (<li>1 {description} document.</li>)
      }
      return (<li>{documentsOfTypeReceived.length} {description} documents.</li>)
    }
  }

  render() {
    this.handleLogout = this.handleLogout.bind(this);
    this.handleStartOver = this.handleStartOver.bind(this);

    const numberOfDocumentsAdded = this.sessionService.getNumberOfDocumentsAdded();
    const groupedDocumentsAdded = groupBy(this.sessionService.getDocumentsSaved(), 'documentType');

    if (this.state.shouldRedirect) {
      return <Redirect to={"/" + this.state.redirectLocation}/>;
    }

    let completeMessage;
    if (numberOfDocumentsAdded > 0) {
      completeMessage =
        <div className="centered documents-received"><FontAwesomeIcon icon={['fas', 'check-square']} size="6x"/>
          <p className="h5">Thank you for sending your documentation, we received</p>
          <ul>
            {this.documentsReceived(groupedDocumentsAdded.proofofidentity, "Proof of Id")}
            {this.documentsReceived(groupedDocumentsAdded.proofofaddress, "Proof of Address")}
            {this.documentsReceived(groupedDocumentsAdded.proofofincome, "Proof of Income")}
            {this.documentsReceived(groupedDocumentsAdded.other, "Other")}
          </ul>
          <p className="h5">We will review them and be in touch soon.</p>
          <div>
            <button type="button" className="btn btn-lg btn-success" onClick={this.handleLogout}>Logout</button>
          </div>
        </div>;
    } else {
      completeMessage =
        <div className="centered"><FontAwesomeIcon icon={['fas', 'square']} size="6x"/> <p className="h5">No documents
          uploaded. You can go back to the start in order to upload some documents or logout.</p>
          <div>
                    <span className="col">
                        <button type="button" className="btn btn-lg btn-success" onClick={this.handleStartOver}>Start over</button>
                    </span>
            <span className="col">
                        <button type="button" className="btn btn-lg btn-outline-success"
                                onClick={this.handleLogout}>Logout</button>
                    </span>
          </div>
        </div>;
    }

    return (
      <div className="container">
        <div className="complete">
          {completeMessage}
        </div>
      </div>
    );
  }
}

export default withAppInsights(CompleteView);