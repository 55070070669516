import React from "react";
import {withRouter} from "react-router-dom";

import SessionService from "../../services/Auth/SessionService";

class BranchHelp extends React.Component {

  render() {
    const sessionService = new SessionService();
    const branch = sessionService.getBranch();

    let branchMessage = '';
    if (branch) {
      const phoneLink = `tel:${branch.telephoneNumber}`;
      branchMessage = (
        <div className="heading h6">
          <div>
            Please contact the {branch.name} on <a href={phoneLink}>{branch.telephoneNumber}</a> and they will be
            able to assist you.
          </div>
          <div>
            Branches are open {branch.openingHours}.
          </div>
        </div>
      )
    } else {
      branchMessage = (
        <div className="heading h6">
          <div>
            Please contact your local branch and they will be able to assist you.
          </div>
        </div>
      )
    }

    return (
      <div className="container personalisation">
        <div className="row">
          <div className="center-block branch-message">
            {branchMessage}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BranchHelp);
