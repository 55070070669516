import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DocumentPreview from "./DocumentPreview.js";
import DocumentCapture from "./DocumentCapture";
import DocumentService from "../../services/Document/DocumentService";
import Alerter from "../App/Alerter";
import {Redirect} from "react-router-dom";
import BackToAmendDocumentType from "./BackToAmendDocumentType";
import MoveNext from "./MoveNext";
import RedirectToLogin from "../App/RedirectToLogin";
import Instructions from "./Instructions";

class ProofOfIdentityView extends React.Component {
  CaptureState = {
    None: 0,
    Front: 1,
    Back: 2,
    Save: 3,
  };

  state = {
    frontDocument: null,
    backDocument: null,
    isSaving: false,
    isSaved: false,
    isError: false,
    isAuthenticated: true,
    errorCount: 0
  };

  handleDocumentReceived(document) {
    document.type = this.getCaptureState();
    this.storeDocumentInState(document);
    this.setState({
      isError: false,
    });
  }

  hideAlert() {
    this.setState({
      isError: false
    });
  }

  async handleFileSaved() {
    const self = this;

    self.setState({
      isFileSaving: true,
    });

    let uploadSuccess = true;

    try {
      let documentService = new DocumentService();
      let result = await documentService.uploadDocument(
        [this.state.frontDocument.file, this.state.backDocument.file],
        "proofofidentity",
        "Drivers License"
      );

      result.forEach(res => {
        if (!res.success) {
          uploadSuccess = false;
        }
      })
    } catch (e) {
      if (e === 401) {
        self.setState({
          isAuthenticated: false
        })
      }
      uploadSuccess = false;
    }

    if (!uploadSuccess) {
      const errorCount = self.state.errorCount;
      self.setState({
        isFileSaving: false,
        isFileSaved: false,
        isError: true,
        errorCount: errorCount + 1
      });
    } else {
      self.setState({
        isFileSaving: false,
        isFileSaved: true,
      });
    }
  }

  handleDocumentRemoved(documentId) {
    if (this.state.frontDocument?.id === documentId) {
      this.setState({
        frontDocument: null,
      });
    }

    if (this.state.backDocument?.id === documentId) {
      this.setState({
        backDocument: null,
      });
    }
  }

  storeDocumentInState(document) {
    if (document.type === this.CaptureState.Front) {
      this.setState({
        frontDocument: document,
      });
    }

    if (document.type === this.CaptureState.Back) {
      this.setState({
        backDocument: document,
      });
    }
  }

  getCaptureState() {
    let hasFront = this.state.frontDocument != null;
    let hasBack = this.state.backDocument != null;

    if (!hasFront) {
      return this.CaptureState.Front;
    }

    if (!hasBack) {
      return this.CaptureState.Back;
    }

    return this.CaptureState.Save;
  }

  getDocumentPreviews() {
    let frontPreview = (
      <div className="col-md-6">
        <DocumentPreview
          title="Frontside Preview"
          document={this.state.frontDocument}
          onDocumentRemoved={this.handleDocumentRemoved}
        />
      </div>
    );

    let backPreview = (
      <div className="col-md-6">
        <DocumentPreview
          title="Backside Preview"
          document={this.state.backDocument}
          onDocumentRemoved={this.handleDocumentRemoved}
        />
      </div>
    );

    return (
      <div className="row previews">
        {frontPreview}
        {backPreview}
      </div>
    );
  }

  render() {
    this.handleFileSaved = this.handleFileSaved.bind(this);
    this.handleDocumentRemoved = this.handleDocumentRemoved.bind(this);
    this.handleDocumentReceived = this.handleDocumentReceived.bind(this);

    if (!this.state.isAuthenticated) {
      return (<RedirectToLogin step="id"/>)
    }

    if (this.state.errorCount > 3) {
      return <Redirect to="/contact"/>;
    }

    const captureState = this.getCaptureState();
    let heading = "";
    if (captureState === this.CaptureState.Front) {
      heading =
        "Please provide the front side of your driving license.";
    }

    if (captureState === this.CaptureState.Back) {
      heading = "Please provide the back side of your driving license.";
    }

    if (this.state.isFileSaving && !this.state.isFileSaved) {
      return (
        <div className="saving">
          <div className="centered">
            <FontAwesomeIcon icon={["fas", "cog"]} spin size="4x"/>
            <p className="h5">Saving, please wait...</p>
          </div>
        </div>
      );
    }

    if (
      this.state.isFileSaved &&
      !this.state.isFileSaving &&
      !this.state.shouldRedirect
    ) {
      return (
        <div className="saved">
          <div className="centered">
            <FontAwesomeIcon icon={["fas", "check"]} size="4x"/>
            <p className="h5">Your proof of identity has been saved!</p>
            <MoveNext documentType="proof of identity" moreCommand={() => this.props.backCommand()}
                      nextCommand={() => this.props.onSaved()}/>
          </div>
        </div>
      );
    }

    if (captureState === this.CaptureState.Save) {
      return (
        <div className="document-selection">
          <div className="heading h5">
            Once you are happy with your driving license photos please save.
          </div>
          <Alerter show={this.state.isError} message="There was an issue uploading your documents. Please try again."
                   hideAlert={() => this.hideAlert()}/>
          <div>
            <button
              type="button"
              className="btn btn-lg btn-success"
              onClick={this.handleFileSaved}
            >
              Save
            </button>
          </div>
          <BackToAmendDocumentType backCommand={() => this.props.backCommand()}/>
          {this.getDocumentPreviews()}
        </div>
      );
    }

    return (
      <div className="document-selection">
        <div className="heading h5">{heading}</div>
        <Instructions/>        
        <BackToAmendDocumentType backCommand={() => this.props.backCommand()}/>
        {captureState !== this.CaptureState.Save ? (
          <DocumentCapture onDocumentReceived={this.handleDocumentReceived}/>
        ) : (
          ''
        )}
        {this.getDocumentPreviews()}
      </div>
    );
  }
}

export default ProofOfIdentityView;
