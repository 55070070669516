import React from "react";
import "./App.scss";
import Alert from "react-bootstrap/Alert";

class Alerter extends React.Component {

  render() {

    if (this.props.show) {
      return (
        <Alert variant="danger" onClose={() => this.props.hideAlert()} dismissible>
          <Alert.Heading>There was an error</Alert.Heading>
          <p>
            {this.props.message}
          </p>
        </Alert>
      );
    } else {
      return (
        <div/>
      );
    }
  }
}

export default Alerter;
