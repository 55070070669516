import React from "react";
import {withRouter} from "react-router-dom";

class AnonymousNavbar extends React.Component {
  render() {
    return (
      <nav className="navbar justify-content-between">
        <a className="navbar-brand" href="/">
          <img
            src="images/everyday-loans-logo-sm.png"
            className="logo"
            alt=""
          />
        </a>
      </nav>
    );
  }
}

export default withRouter(AnonymousNavbar);
