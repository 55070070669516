import React from "react";
import {Redirect} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import "./LoginView.css";

import AuthenticationService from "../../services/Auth/AuthenticationService";
import SessionService from "../../services/Auth/SessionService";
import Alerter from "../../components/App/Alerter";
import withAppInsights from '../../services/Monitoring/AppInsights';

class LoginView extends React.Component {
  state = {
    isApplicationStatusValid: false,
    isAuthenticated: false,
    hasFormSubmitted: false,
    failedLoginAttempts: 0,
    redirectState: this.props.location.state,
    isHuman: true,
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      linkId: this.props.match.params.linkId
    });
  }

  applicationInValidState(currentState) {
    return currentState === "Proposal" 
        || currentState === "Active" 
        || currentState === "Frozen";
  }

  loginFailed() {
    let {failedLoginAttempts} = this.state;
    failedLoginAttempts = failedLoginAttempts + 1;

    this.setState({
      hasFormSubmitted: true,
      failedLoginAttempts,
      isHuman: failedLoginAttempts < 5,
    });
  }

  years() {
    const currentYear = new Date().getFullYear(),
      years = [];
    let startYear = currentYear - 100;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  }

  formatDateElement(input) {
    if (input < 10) {
      return "0" + input;
    }
    return input;
  }

  async handleSubmit(event) {
    event.preventDefault();

    const surname = event.target.Surname.value;
    const dob =
      event.target.Year.value +
      "-" +
      this.formatDateElement(event.target.Month.value) +
      "-" +
      this.formatDateElement(event.target.Day.value);
    const postCode = event.target.PostCode.value;

    if (surname === "" || dob === "" || postCode === "") {
      this.loginFailed();
      return;
    }

    let authenticationService = new AuthenticationService();

    try {
      const customerToken = await authenticationService.authenticate(
        this.state.linkId,
        surname,
        dob,
        postCode
      );

      if (this.applicationInValidState(customerToken.applicationStatus)) {
        let sessionService = new SessionService();
        sessionService.setToken(customerToken.token);
        sessionService.setCustomerId(customerToken.customerId);
        sessionService.setCustomerName(customerToken.customerFirstName);
        sessionService.setApplicationId(customerToken.applicationId);
        sessionService.setLinkId(this.state.linkId);
        sessionService.setBranch(customerToken.branch);
      }

      this.setState({
        hasFormSubmitted: true,
        isAuthenticated: true,
        isApplicationStatusValid: this.applicationInValidState(
          customerToken.applicationStatus
        ),
      });
    } catch (error) {
      // increment failed login attempts
      this.loginFailed();
    }
  }

  verifyHuman() {
    this.setState({
      isHuman: true,
      hasFormSubmitted: false,
    });
  }

  render() {
    const {
      isAuthenticated,
      isApplicationStatusValid,
      hasFormSubmitted,
      isHuman,
    } = this.state;

    if (isAuthenticated) {
      if (!isApplicationStatusValid) {
        return <Redirect to="/invalid"/>;
      }

      if (this.state.redirectState
        && this.state.redirectState.step) {
        return <Redirect to={`/${this.state.redirectState.step}`}/>;
      }

      return <Redirect to="/id"/>;
    }

    return (
      <div>
        <img
          src={"/images/everyday-loans-logo-sm.png"}
          className="login-logo"
          alt="Everyday Loans"
        />
        <Alerter show={this.state.redirectState}
                 message="Your session has timed out. Please login and continue."
                 hideAlert={() => this.hideAlert()}/>
        <span className="h5">Please enter your personal details.</span>
        <div className="login-container">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group text-left">
              <label
                className="col-form-label col-form-label"
                htmlFor="Surname"
              >
                Surname:
              </label>
              <input
                name="Surname"
                id="Surname"
                className="form-control"
                type="text"
                placeholder=""
                maxLength="100"
                required
              />
            </div>
            <div className="form-group text-left">
              <label className="col-form-label col-form-label" htmlFor="Day">
                Date of Birth:
              </label>
              <div className="row">
                <div className="col date-divider">
                  <select
                    name="Day"
                    id="Day"
                    className="form-control"
                    placeholder="dd"
                    required
                  >
                    <option value="">dd</option>
                    {Array.from(Array(31), (e, i) => {
                      return (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col date-divider">
                  <select name="Month" className="form-control" required>
                    <option value="">mm</option>
                    {Array.from(Array(12), (e, i) => {
                      return (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col">
                  <select name="Year" className="form-control" required>
                    <option value="">yyyy</option>
                    {this.years().map((item, i) => {
                      return (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group text-left">
              <label
                className="col-form-label col-form-label"
                htmlFor="PostCode"
              >
                Post Code:
              </label>
              <input
                name="PostCode"
                id="PostCode"
                className="form-control form-control"
                type="text"
                pattern="([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})"
                placeholder=""
                required
              />
            </div>

            {isHuman && (
              <div className="form-group">
                <button type="submit" className="btn btn-primary">
                  Login
                </button>
              </div>
            )}

            {isHuman && hasFormSubmitted && !isAuthenticated && (
              <div className="form-group text-danger">
                Your login details are incorrect.
              </div>
            )}

            {!isHuman && (
              <div>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={() => this.verifyHuman()}
                />
                <div className="form-group text-danger">
                  Your login details are incorrect.
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
}

export default withAppInsights(LoginView);
