import React from "react";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import Dropzone from "react-dropzone";
import "./DocumentCapture.scss";
import {ButtonGroup, Button} from "react-bootstrap";
import Alerter from "../App/Alerter";
import {isIE} from "../../utils";

class DocumentCapture extends React.Component {
  CaptureMethod = {
    File: 0,
    Camera: 1,
  };

  state = {
    isCameraEnabled: true,
    captureMethod: this.CaptureMethod.Camera,
    isError: false,
    errorMessage: "",
  };

  constructor(props) {
    super(props);
    this.handleFilesDropped = this.handleFilesDropped.bind(this);
    this.handlePhotoTaken = this.handlePhotoTaken.bind(this);
  }

  validateFile(file) {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;

    // Allowing file type
    if (!allowedExtensions.exec(file.name)) {
      this.setState({
        isError: true,
        errorMessage: `The selected document ${file.name} is of the wrong type. Only PDF and image types are allowed.`,
      });
      return false;
    }

    // Allowing file size
    if (file.size > 31457280) {
      this.setState({
        isError: true,
        errorMessage: `The selected document ${file.name} is too large. The maximum file size is 30MB.`,
      });
      return false;
    }

    if (file.size === 0) {
      this.setState({
        isError: true,
        errorMessage: `The selected document ${file.name} is empty.`,
      });
      return false;
    }

    return true;
  }

  generateId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
      return v.toString(16);
    });
  }

  handleFilesDropped(droppedFiles) {
    for (let i = 0; i < droppedFiles.length; i++) {
      const file = droppedFiles[i];

      if (this.validateFile(file)) {
        let mimetype = file.name.toUpperCase().endsWith("PDF") ? "pdf" : "image";
        let preview;
        preview = URL.createObjectURL(file);

        const document = {
          id: this.generateId(),
          file: file,
          name: file.name,
          preview,
          mimetype
        };

        this.props.onDocumentReceived(document);
      }
    }
  }

  handlePhotoTaken(photo) {
    const document = {
      id: this.generateId(),
      file: photo,
      preview: photo,
    };

    this.props.onDocumentReceived(document);
  }

  hideAlert() {
    this.setState({
      isError: false,
    });
  }

  setCameraDisabled() {
    this.setState({
      isCameraEnabled: false,
      captureMethod: this.CaptureMethod.File,
    });
  }

  toggleCaptureMethod() {
    const currentMethod = this.state.captureMethod;
    if (currentMethod === this.CaptureMethod.Camera) {
      this.setState({
        captureMethod: this.CaptureMethod.File,
      });
    } else {
      this.setState({
        captureMethod: this.CaptureMethod.Camera,
      });
    }
  }

  cameraError() {
    this.setCameraDisabled();

    if (!isIE()) {
      this.setState({
        isError: true,
        errorMessage: `The camera cannot be used. Please check if another application is using it.`,
      });
    }
  }

  render() {
    let capture;
    const isCameraEnabled = this.state.isCameraEnabled;
    const captureMethod = this.state.captureMethod;

    if (isCameraEnabled && captureMethod === this.CaptureMethod.Camera) {
      capture = (
        <Camera
          onCameraError={() => this.cameraError()}
          isImageMirror={false}
          idealFacingMode={'environment'}
          isDisplayStartCameraError={false}
          onTakePhoto={(photo) => {
            this.handlePhotoTaken(photo);
          }}
        />
      );
    } else {
      capture = (
        <Dropzone onDrop={this.handleFilesDropped}>
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps({className: "dropzone"})}>
              <input {...getInputProps()} />
              <p className="caption">
                Drag and drop files here, or click to select files to upload.
              </p>
            </div>
          )}
        </Dropzone>
      );
    }

    return (
      <div>
        <Alerter
          show={this.state.isError}
          message={this.state.errorMessage}
          hideAlert={() => this.hideAlert()}
        />
        {isCameraEnabled && (
          <div className="container h6">
            <ButtonGroup toggle>
              <Button
                className={`btn-success ${
                  this.state.captureMethod === this.CaptureMethod.File
                    ? "active"
                    : ""
                }`}
                disabled={this.state.captureMethod === this.CaptureMethod.File}
                onClick={() => this.toggleCaptureMethod()}
              >
                Use a document
              </Button>
              <Button
                className={`btn-success ${
                  this.state.captureMethod === this.CaptureMethod.Camera
                    ? "active"
                    : ""
                }`}
                disabled={
                  this.state.captureMethod === this.CaptureMethod.Camera
                }
                onClick={() => this.toggleCaptureMethod()}
              >
                Take a picture
              </Button>
            </ButtonGroup>
          </div>
        )}
        <div className="row">
          <div className="col no-padding">{capture}</div>
        </div>
      </div>
    );
  }
}

export default DocumentCapture;
