import React from "react";
import {withRouter, Redirect} from "react-router-dom";
import DocumentTypeSelector from "../../components/Documents/DocumentTypeSelector";
import Steps from "../../components/App/Steps";
import DriversLicenseCapture from "../../components/Documents/DriversLicenceCapture";
import PassportCapture from "../../components/Documents/PassportCapture";
import "./index.scss";
import GeneralDataCapture from "../../components/Documents/GeneralDataCapture";
import SessionService from "../../services/Auth/SessionService";
import withAppInsights from '../../services/Monitoring/AppInsights';

class ProofOfIdentityView extends React.Component {
  state = {
    documentType: null,
    shouldRedirect: false,
  };

  handleDocumentTypeSelected(documentType) {
    this.setState({
      documentType,
    });
  }

  handleDocumentsSaved() {
    this.setState({
      shouldRedirect: true,
    });
  }

  handleDocumentTypeReset() {
    this.setState({
      documentType: null,
      shouldRedirect: false,
    });
  }

  render() {
    this.handleDocumentsSaved = this.handleDocumentsSaved.bind(this);
    this.handleDocumentTypeSelected = this.handleDocumentTypeSelected.bind(
      this
    );

    const sessionService = new SessionService();
    const customerName = sessionService.getCustomerName();

    const documentType = this.state.documentType;
    const isDocumentTypeSelected = documentType != null;
    let documentCapture;

    if (this.state.shouldRedirect) {
      return <Redirect to="/address"/>;
    }

    if (documentType === "passport") {
      documentCapture = <PassportCapture onSaved={this.handleDocumentsSaved}
                                         backCommand={() => this.handleDocumentTypeReset()}/>;
    }

    if (documentType === "license") {
      documentCapture = (
        <DriversLicenseCapture onSaved={this.handleDocumentsSaved}
                               backCommand={() => this.handleDocumentTypeReset()}/>
      );
    }

    if (documentType === "other") {
      documentCapture = (
        <GeneralDataCapture
          backCommand={() => this.handleDocumentTypeReset()}
          proofType="proofofidentity"
          proofDescription="proof of id"
          documentDescription="proof of id"
          currentStage="id"
          nextStage="address"
        />
      );
    }

    if (isDocumentTypeSelected) {
      return (
        <div>
          <Steps currentStep={1}/>
          {documentCapture}
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <Steps currentStep={1}/>
            <div className="heading h6 personalisation">
              Thank you {customerName} for securely logging in.
              Following on from your conversation with your branch, please upload your required documentation.
            </div>
            <DocumentTypeSelector
              onDocumentTypeSelected={this.handleDocumentTypeSelected}
            />
          </div>
          <div className=" skip heading h6">
            or
            <button
              type=" button"
              className=" btn btn-sm btn-success"
              onClick={() => this.props.history.push(`/address`)}
            >
              Skip
            </button>
            to the next stage.
          </div>
        </div>
      );
    }
  }
}

export default withAppInsights(withRouter(ProofOfIdentityView));
