import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Stepper from 'bs-stepper';
import 'bs-stepper/dist/css/bs-stepper.min.css';

class Steps extends React.Component {

  componentDidMount() {
    const stepper = new Stepper(document.querySelector('.bs-stepper'));
    stepper.to(this.props.currentStep);
  }

  render() {

    return (
      <section className="container steps">
        <div className="bs-stepper">
          <div className="bs-stepper-header" role="tablist">
            <div className="step" data-target="#logins-part">
              <button type="button" className="step-trigger" role="tab" aria-controls="logins-part"
                      id="logins-part-trigger">
                {
                  (this.props.currentStep > 1) ?
                    <span className="bs-stepper-circle" style={{"color": "white", "backgroundColor": "green"}}> 
                                <FontAwesomeIcon icon={['fas', 'check']}/>
                            </span> :
                    <span className="bs-stepper-circle">1</span>
                }

                {
                  (this.props.currentStep > 1)
                    ? <div>
                        <span className="bs-stepper-label h6 full-text"
                              style={{"color": "green"}}>Proof of identity</span>
                      <span className="bs-stepper-label h6 short-text" style={{"color": "green"}}>Id</span>
                    </div>
                    : <div>
                      <span className="bs-stepper-label h6 full-text">Proof of identity</span>
                      <span className="bs-stepper-label h6 short-text">Id</span>
                    </div>
                }
              </button>
            </div>
            <div className="step" data-target="#information-part">
              <button type="button" className="step-trigger" role="tab" aria-controls="information-part"
                      id="information-part-trigger">
                {
                  (this.props.currentStep > 2) ?
                    <span className="bs-stepper-circle" style={{"color": "white", "backgroundColor": "green"}}> 
                                <FontAwesomeIcon icon={['fas', 'check']}/>
                            </span> :
                    <span className="bs-stepper-circle">2</span>
                }

                {
                  (this.props.currentStep > 2) ?
                    <div>
                        <span className="bs-stepper-label h6 full-text"
                              style={{"color": "green"}}>Proof of address</span>
                      <span className="bs-stepper-label h6 short-text" style={{"color": "green"}}>Address</span>
                    </div> :
                    <div>
                      <span className="bs-stepper-label h6 full-text">Proof of address</span>
                      <span className="bs-stepper-label h6 short-text">Address</span>
                    </div>
                }
              </button>
            </div>
            <div className="step" data-target="#information-part">
              <button type="button" className="step-trigger" role="tab" aria-controls="information-part"
                      id="information-part-trigger">
                {
                  (this.props.currentStep > 3) ?
                    <span className="bs-stepper-circle" style={{"color": "white", "backgroundColor": "green"}}> 
                                <FontAwesomeIcon icon={['fas', 'check']}/>
                            </span> :
                    <span className="bs-stepper-circle">3</span>
                }

                {
                  (this.props.currentStep > 3) ?
                    <div>
                        <span className="bs-stepper-label h6 full-text"
                              style={{"color": "green"}}>Proof of income</span>
                      <span className="bs-stepper-label h6 short-text" style={{"color": "green"}}>Income</span>
                    </div> :
                    <div>
                      <span className="bs-stepper-label h6 full-text">Proof of income</span>
                      <span className="bs-stepper-label h6 short-text">Income</span>
                    </div>
                }
              </button>
            </div>
            <div className="step" data-target="#information-part">
              <button type="button" className="step-trigger" role="tab" aria-controls="information-part"
                      id="information-part-trigger">
                {
                  (this.props.currentStep > 4) ?
                    <span className="bs-stepper-circle" style={{"color": "white", "backgroundColor": "green"}}> 
                                <FontAwesomeIcon icon={['fas', 'check']}/>
                            </span> :
                    <span className="bs-stepper-circle">4</span>
                }

                {
                  (this.props.currentStep > 4) ?
                    <div>
                        <span className="bs-stepper-label h6 full-text"
                              style={{"color": "green"}}>Additional documents</span>
                      <span className="bs-stepper-label h6 short-text" style={{"color": "green"}}>Additional</span>
                    </div> :
                    <div>
                      <span className="bs-stepper-label h6 full-text">Additional documents</span>
                      <span className="bs-stepper-label h6 short-text">Additional</span>
                    </div>
                }
              </button>
            </div>
          </div>
          <div className="bs-stepper-content">
            <div id="logins-part" className="content" role="tabpanel" aria-labelledby="logins-part-trigger"/>
            <div id="information-part" className="content" role="tabpanel"
                 aria-labelledby="information-part-trigger"/>
          </div>
        </div>
      </section>
    );
  }
}

export default Steps;